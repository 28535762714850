/* ---Calavera--- */
/* Splashpage */
.calavera .splash {
  font-weight: 700;
}

.calavera .splash button {
  color: white;
  font-weight: 700;
  background: #d5076e;
  border-radius: 30px;
  padding: 1rem;
}

/* .calavera .calavera_course_image {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    transparent 100%
  );
} */

.calavera .cta-item > div,
.calavera .modal-wrapper.ion-overlay-wrapper {
  background: #faf2d5;
}

.calavera .cta-item .dish-variations > div div,
.calavera .cta-item .dish-additions > div div div {
  background: #d5076e;
  border-radius: 8px;
  color: white;
  border-left-width: 0;
}

.calavera .arrow-trigger {
  background: none;
  box-shadow: none;
  color: white;
}

/* custom collapsibles */
.calavera .menu {
  padding: 0;
  background: #faf2d5;
  border-radius: 0;
}

.calavera .collapsible-trigger.trigger {
  color: white;
}

/* .calavera div.menu div:nth-child(1) span div {
  background: #f4d936;
  color: black;
}

.calavera div.menu div:nth-child(2) span div {
  background: #f09b32;
  color: black;
}

.calavera div.menu div:nth-child(3) span div {
  background: #e76149;
  color: black;
}

.calavera div.menu div:nth-child(4) span div {
  background: #dd265f;
  color: black;
}

.calavera div.menu div:nth-child(5) span div {
  background: #d91f7e;
  color: black;
}

.calavera div.menu div:nth-child(6) span div {
  background: #d92d85;
  color: black;
}

.calavera div.menu div:nth-child(7) span div {
  background: #de91b8;
  color: black;
} */

/* calavera collapsibles content */
/* .calavera div.menu div:nth-child(1) div .nested {
  background: #f9e540 !important;
  color: black;
} */

/* .calavera div.menu div:nth-child(2) div .nested {
  background: #f09b32;
  color: black;
} */

/* .calavera div.menu div:nth-child(3) div .nested {
  background: #e76149;
  color: black;
}
.calavera div.menu div:nth-child(4) div .nested {
  background: #dd265f;
  color: black;
} */
/* .calavera div.menu div:nth-child(5) div .nested {
  background: #d91f7e;
  color: black;
}
.calavera div.menu div:nth-child(6) div .nested {
  background: #d92d85;
  color: black;
}
.calavera div.menu div:nth-child(7) div .nested {
  background: #de91b8;
  color: black;
} */
/* --------- */
/* .calavera .nested .Collapsible .even {
  background: #f9e540;
  color: black;
}

.calavera .nested .Collapsible .odd {
  background: #f9e540;
  color: black;
} */

/* ------------ */
/* .calavera .nested div span div {
  border-radius: 10px;
  border: solid white 2px;
  margin: 1rem;
}

.calavera .nested > div > div {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: -1rem;
  border-radius: 10px;
  border-left: solid white 2px;
  border-right: solid white 2px;
}

.calavera .is-open {
  border-bottom: 0;
} */

.calavera .calavera-trigger-opened {
  border-bottom: 0;
}

.calavera .calavera-trigger-closed {
  border-bottom: solid white 2px;
}

.calavera .dish div {
  border-radius: 20px;
  background: #faf2d5;
}

.calavera .cta-no-orders,
.calavera .cta-no-orders button {
  background: #faf2d5;
  border: #faf2d5;
  margin-top: 5px;
  border-radius: 12px;
  color: black;
}

.calavera .filter-modal-trigger {
  padding: 1rem;
  background: #f4e151;
  border-color: #f4e151;
  color: black;
  border-width: 2px;
  font-weight: 700;
}

.calavera .dish-modal-trigger {
  color: #b7172e;
}

.calavera .allergens-modal-trigger span,
.calavera .allergens-modal-trigger a {
  border-color: #d5076e;
  padding: 1rem;
  color: white;
  background: #d5076e;
  border-width: 2px;
  font-weight: 700;
}

.calavera .powered-by {
  color: black;
  font-weight: bold;
}

.calavera .powered-by a {
  color: black;
  font-weight: 800;
}
